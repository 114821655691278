#events {
	padding-bottom: 0;
}

#events .g {
	align-content: unset;
}

.events__titles {
    align-self: center;
    grid-column: 6 / -2;
}

.events__photo {
    grid-column: 1 / 5;
}

@media screen and (max-width: 768px) {
	.events__titles, .events__photo {
		grid-column: 1 / -1;
	}

	.events__photo {
		grid-column: 1 / -1;
		grid-row: 2 / 3;
		height: auto;
		max-width: 260px;
		margin: 1.5rem auto 0;
	}
}
