#plane {
    position: absolute;
    opacity: 0;
    transform-origin: center center;
}

#path_with_plane {
    position: absolute;
    width: 100%;
    z-index: -1;
}

@media screen and (max-width: 90rem){
    #path_with_plane, #plane {
        display: none;
    }
}
