.soon_button {
    display: flex;
    color: black;
    padding-left: 1rem;
    border-radius: 0.5rem;
    height: 4.25rem;
    align-items: center;
    margin-bottom: 1rem;
    border: 2px solid #A9A9A9;
}

.soon_button__image  {
    width: 2rem;
    max-height: 2.5rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.soon_button__text {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.soon_button__title {
    font-size: 0.875rem;
}

.soon_button__subtitle {
    font-size: 1.125rem;
    font-weight: 700;
}

.soon_button__link_text {
    color: #007AFF;
    text-decoration: underline;
}

.soon_button__title, .soon_button__link_text {
    line-height: 1.05rem;
}

.soon_button__subtitle {
    line-height: 1.35rem;
}

@media screen and (width <= 1024px) {
    .soon_button {
        height: 3.75rem;
        margin-bottom: 0.5rem;
    }

    .soon_button__title {
        font-size: 0.75rem;
    }

    .soon_button__subtitle {
        font-size: 1rem;
    }

    .soon_button__link_text {
        font-size: 0.75rem;
    }

    .soon_button__title, .soon_button__link_text {
        line-height: 0.9rem;
    }

    .soon_button__subtitle {
        line-height: 1.2rem;
    }

    .soon_button__image  {
        width: 1.5rem;
    }
}

@media screen and (width <= 768px) {
    .soon_button__image  {
        padding-right: 0.5rem;
    }
}