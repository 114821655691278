.store {
    margin-top: 1rem;
    gap: 1rem;
    display: grid;
    grid-template: 1fr/ 216px 1fr 216px 1fr;
}

.store--bottom {
    grid-template: 1fr / 216px 0px 216px;
    gap: 2rem;
}

.store__column--second {
    grid-area: 1 / 3 / 1 / span 1;
}

.store__column_title {
    font-size: 18px;
    font-weight: 900;
    line-height: 2.7rem;
    color: #303030;
}

.store__title_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    margin-bottom: 1rem;
}

.store__title_icon {
    height: 2rem;
    padding-right: 0.8rem;
}

@media screen and (width <= 1024px) {
    .store {
        grid-template: 1fr/ 1fr 1fr;
    }

    .store__column--second {
        grid-area: 1 / 2 / 1 / span 1;
    }

    .store__column_title {
        font-size: 0.875rem;
        line-height: 1.05rem;
    }

    .store__title_container {
        height: 2rem;
    }

    .store__title_icon {
        height: 1.6rem;
        padding-right: 0.6875rem;
    }
}

@media screen and (width <= 768) {
    .store__title_container {
        height: 3.5rem;
        flex-direction: column;
        justify-content: space-around;
    }

    .store__title_icon {
        height: 1.6rem;
        padding-right: 0;
    }
}