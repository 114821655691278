.overlay {
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    background: rgba(23, 23, 26, .5);
    z-index: 10;
}

.modal {
    justify-content: center;
}

.modal--visible {
    display: flex;
}

.modal__dialog {
    background: white;
    border-radius: 1rem;
    height: min-content;
    margin: 5rem 1rem 1rem;
    max-width: 30rem;
    padding: 3rem;
    position: relative;
    width: 100%;
}

.modal__dialog.afb {
    animation-duration: .3s;
}

.modal__close {
    background: transparent;
    color: var(--primary-color);
    padding: .5rem;
    position: absolute;
    right: .5rem;
    top: .5rem;
}
