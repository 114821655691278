@keyframes Appearance {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes AppearanceFromBottom {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes AppearanceFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.a {
    animation-name: Appearance;
    animation-duration: .3s;
    animation-timing-function: cubic-bezier(.1, -.6, .2, 0);
}

.afb {
    animation-name: AppearanceFromBottom;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.1, -.6, .2, 0);
}

.afl {
    animation-name: AppearanceFromLeft;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(.1, -.6, .2, 0);
}