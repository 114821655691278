#projects_summary {
    padding-top: 0;
    padding-bottom: 0;
}

#projects_summary .g {
    align-content: unset;
}

.projects_summary__titles {
    align-self: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-column: 1 / 7;
}

.projects_summary__photo {
    grid-column: 8 / -2;
}

.projects_summary__features {
    grid-template-columns: 1fr 1fr;
    margin-top: 4rem;
}

@media screen and (max-width: 768px) {
    #projects_summary {
        padding-bottom: 0;
    }

    .projects_summary__titles, .projects_summary__features, .projects_summary__photo {
        grid-column: 1 / -1;
    }

    .projects_summary__photo {
        grid-column: 1 / -1;
        height: auto;
        max-width: 260px;
        margin: 1.5rem auto 0;
    }

    .projects_summary__features {
        grid-template-columns: 1fr;
        margin-top: 1rem;
    }
}
