:root {
  --primary-color: #232326;
  --secondary-color: #616161;
  --accent-color: #1c59f6;
  --accent-medium-color: #007aff;
  --preview-color: #fcfdff;
}

html, body {
  color: var(--primary-color);
  margin: 0;
  padding: 0;
  font-family: Lato, Avenir, Adobe Heiti Std, Segoe UI, Trebuchet MS, sans-serif;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

img[loading="lazy"] {
  opacity: 0;
}

img.loaded {
  opacity: 1;
}

@keyframes Appearance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes AppearanceFromBottom {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes AppearanceFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.a {
  animation-name: Appearance;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.1, -.6, .2, 0);
}

.afb {
  animation-name: AppearanceFromBottom;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(.1, -.6, .2, 0);
}

.afl {
  animation-name: AppearanceFromLeft;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(.1, -.6, .2, 0);
}

body, h1, h2, h3, h4, h5, h6, p, a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: lato, sans-serif;
}

h1 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 4.5rem;
  font-weight: 600;
}

h2 {
  margin: 1rem 0;
  font-size: 1.875rem;
  font-weight: 600;
}

h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

p {
  margin: 0 0 .5rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
}

button, input {
  font-size: 1.125rem;
}

@media screen and (width <= 1440px) {
  h1 {
    margin-bottom: 1.5rem;
    font-size: 3.25rem;
  }

  h2 {
    font-size: 1.625rem;
  }

  p {
    font-size: 1.25rem;
  }
}

@media screen and (width <= 1024px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }
}

@media screen and (width <= 768px) {
  h1 {
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.375rem;
  }

  p, button, input {
    font-size: 1rem;
  }
}

.g {
  box-sizing: border-box;
  grid-template-columns: repeat(12, 1fr);
  align-content: center;
  column-gap: 1rem;
  max-width: 90rem;
  height: 100%;
  margin: 0 auto;
  padding-left: 4rem;
  padding-right: 4rem;
  display: grid;
}

main {
  position: relative;
}

section {
  height: 780px;
  position: relative;
}

picture, img {
  object-fit: contain;
  object-position: bottom;
  z-index: 1;
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1440px) {
  .g {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  section {
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media screen and (width <= 768px) {
  section {
    height: auto;
  }

  .g {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

nav {
  z-index: 2;
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0 1px .5rem 1px #eee;
}

nav > .g {
  align-items: center;
  min-height: 4rem;
}

.nav__link {
  padding: .5rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  text-decoration: none;
}

.burger {
  color: var(--primary-color);
  background: none;
  width: min-content;
  padding: 0;
  display: none;
}

.logo {
  padding: .75rem 0;
}

.lang {
  grid-area: 1 / -2 / 2 / -1;
}

.nav {
  grid-column: 2 / -2;
  align-items: center;
  gap: 4rem;
  display: flex;
}

.nav--visible {
  display: flex !important;
}

.nav__overlay--visible {
  display: block !important;
}

#start {
  grid-template-rows: repeat(6, auto);
  margin: 6rem auto 0;
}

.start__title {
  grid-column: 1 / 8;
  margin-top: 6rem;
}

.start__description, .start__download {
  grid-column: 1 / 8;
}

.start__photo {
  grid-area: 1 / 8 / -1 / -2;
}

@media screen and (width <= 1440px) {
  .start__title {
    grid-column: 1 / 7;
    margin-top: 10%;
  }

  .start__description, .start__download {
    grid-column: 1 / 7;
  }
}

@media screen and (width <= 1240px) {
  .start__title {
    grid-column: 1 / 8;
  }
}

@media screen and (width <= 1024px) {
  .start__title {
    margin-bottom: 1rem;
  }

  .start__photo {
    grid-area: 1 / 8 / -1 / -1;
  }

  .nav {
    gap: 2rem;
  }
}

@media screen and (width <= 768px) {
  #start {
    margin-top: 4rem;
  }

  .start__title {
    grid-column: 1 / -1;
    margin-top: 0;
  }

  nav > .g {
    min-height: auto;
  }

  .start__description, .start__download {
    grid-column: 1 / -1;
  }

  .start__photo {
    grid-area: 3 / 1 / 4 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto;
  }

  .burger {
    grid-area: 1 / 1 / 1 / 2;
    display: block;
  }

  .logo {
    grid-area: 1 / 1 / 1 / -1;
    justify-self: center;
  }

  .nav {
    flex-direction: column;
    grid-column: 1 / -1;
    gap: 0;
    padding: 1rem;
    display: none;
  }

  .nav__link {
    text-align: center;
    width: 100%;
    padding: 1rem;
  }
}

.footer__social {
  grid-auto-flow: column;
  display: grid;
}

.footer__social a {
  padding: .5rem;
}

.footer__social, .footer__agreement, .footer__copyright {
  text-align: center;
  grid-column: 1 / -1;
  justify-content: center;
}

.footer__copyright {
  color: gray;
  padding: .5rem 0 2rem;
  font-size: .8rem;
}

.form {
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  margin-top: 2rem;
  display: grid;
}

.form__email {
  grid-column: 1 / 4;
}

.form__submit {
  grid-column: 4 / -1;
}

.form__footer {
  grid-column: 1 / -1;
}

.form__error {
  color: red;
  margin: 0;
  padding-top: .5rem;
  font-size: 1rem;
}

.form__error--hidden {
  display: none;
}

input {
  appearance: none;
  border: 2px solid var(--secondary-color);
  border-radius: 4px;
  padding: 1rem;
  line-height: 1.375rem;
}

button {
  color: #fff;
  background: var(--accent-medium-color);
  border: none;
  border-radius: 4px;
  padding: 1rem;
  line-height: 1.375rem;
}

@media screen and (width <= 1024px) {
  .form {
    row-gap: 1rem;
    margin-top: 1.5rem;
  }

  .form__email, .form__submit {
    grid-column: 1 / -1;
  }

  input, button {
    padding: .75rem;
  }
}

.overlay {
  z-index: 10;
  background: #17171a80;
  display: none;
  position: fixed;
  inset: 0;
}

.modal {
  justify-content: center;
}

.modal--visible {
  display: flex;
}

.modal__dialog {
  background: #fff;
  border-radius: 1rem;
  width: 100%;
  max-width: 30rem;
  height: min-content;
  margin: 5rem 1rem 1rem;
  padding: 3rem;
  position: relative;
}

.modal__dialog.afb {
  animation-duration: .3s;
}

.modal__close {
  color: var(--primary-color);
  background: none;
  padding: .5rem;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.features {
  align-items: baseline;
  gap: 1.5rem;
  display: grid;
}

.feature {
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: .5rem 1.5rem;
  display: grid;
}

.feature__checkbox {
  grid-column: 1 / 2;
  width: 1.5rem;
  height: 1.5rem;
}

.feature__title {
  grid-column: 2 / 3;
  margin: 0;
  font-size: 1.5rem;
}

.feature__description {
  grid-area: 2 / 1 / 3 / 3;
}

.features p {
  font-size: 1.2rem;
}

@media screen and (width <= 1440px) {
  .features {
    gap: 2rem;
  }
}

@media screen and (width <= 1024px) {
  .features {
    gap: 1.5rem;
  }

  .feature {
    column-gap: 1rem;
  }

  .feature__title {
    font-size: 1.25rem;
  }
}

@media screen and (width <= 768px) {
  .features {
    gap: 1rem;
  }

  .feature__title, .features p {
    font-size: 1rem;
  }
}

#welcome {
  display: flex;
}

.welcome__titles {
  grid-column: 1 / 9;
}

.welcome__benefits {
  scroll-snap-type: x mandatory;
  grid-template-columns: repeat(3, minmax(18rem, 20rem));
  justify-content: space-between;
  gap: 1rem;
  display: grid;
  overflow-x: auto;
  overflow-y: hidden;
}

.welcome__slider {
  grid-column: 1 / -1;
  margin-top: 4rem;
  overflow: hidden;
}

.benefit {
  scroll-snap-align: center;
  width: 100%;
}

.benefit p {
  font-size: 1.25rem;
}

.benefit__image {
  max-height: 10rem;
}

#welcome .slider__navigation {
  display: none;
}

@media screen and (width <= 1024px) {
  .welcome__titles {
    grid-column: 1 / -1;
  }

  #welcome .slider__navigation {
    display: flex;
  }

  .welcome__benefits {
    grid-template-columns: repeat(3, 100%);
    padding-bottom: 2rem;
  }

  .benefit {
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    display: grid;
  }

  .benefit__image {
    justify-self: center;
    width: auto;
  }

  .benefit__titles {
    max-width: 30rem;
  }
}

@media screen and (width <= 768px) {
  .welcome__titles {
    grid-column: 1 / -1;
  }

  .benefit p {
    font-size: 1rem;
  }
}

@media screen and (width <= 540px) {
  .benefit {
    grid-template-columns: auto;
  }

  .benefit__titles {
    max-width: 20rem;
  }
}

#tasks {
  padding-bottom: 0;
}

.tasks__titles {
  grid-column: 6 / -2;
  align-self: center;
}

.tasks__photo {
  grid-column: 1 / 5;
}

@media screen and (width <= 768px) {
  #tasks {
    background-image: url("data:image/svg+xml, %3Csvg width='360' height='469' viewBox='0 0 360 469' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='360' height='469'%3E%3Crect width='360' height='469' fill='%23FCFDFF'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath d='M357.403 330C281.542 391.441 288.267 476.552 357.403 539' stroke='%23404040' stroke-dasharray='10 10'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }

  .tasks__titles, .tasks__photo {
    grid-column: 1 / -1;
  }

  .tasks__photo {
    grid-area: 2 / 1 / 3 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto 0;
  }
}

.tasks_summary__titles {
  grid-column: 1 / 5;
  align-self: center;
}

.tasks_summary__photo {
  grid-column: 6 / 9;
}

.tasks_summary__photo img {
  object-position: center;
}

.tasks_summary__features {
  margin-top: 4rem;
}

@media screen and (width <= 768px) {
  #tasks_summary {
    background-image: url("data:image/svg+xml, %3Csvg width='360' height='572' viewBox='0 0 360 572' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='360' height='572'%3E%3Crect width='360' height='572' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath d='M360 503C261.638 462.335 176.523 403.5 359.999 203.5' stroke='%23404040' stroke-dasharray='10 10'/%3E%3Cpath d='M357.403 -139C281.542 -77.559 288.268 7.55182 357.403 70' stroke='%23404040' stroke-dasharray='10 10'/%3E%3C/g%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M315.78 255.853L316.747 260.847L300.072 273.999L314.551 259.891L315.78 255.853Z' fill='%23E22BDB'/%3E%3Cpath d='M306.513 256.973L314.552 259.892L300.072 273.999L306.513 256.973Z' fill='%237F36F8'/%3E%3Cpath d='M316.747 260.848L322.105 262.088L300.073 273.999L316.747 260.848Z' fill='%23F8B70C'/%3E%3Cpath d='M314.54 259.928L315.768 255.89L312.809 259.308L314.54 259.928Z' fill='%23FE452B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white' transform='translate(309.146 281.853) rotate(-139.12)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-position: 100% 0;
    background-repeat: no-repeat;
    padding-bottom: 0;
  }

  .tasks_summary__titles, .tasks_summary__photo {
    grid-column: 1 / -1;
  }

  .tasks_summary__photo {
    grid-area: 2 / 1 / 3 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto 0;
  }

  .tasks_summary__features {
    margin-top: 1rem;
  }
}

#events {
  padding-bottom: 0;
}

#events .g {
  align-content: unset;
}

.events__titles {
  grid-column: 6 / -2;
  align-self: center;
}

.events__photo {
  grid-column: 1 / 5;
}

@media screen and (width <= 768px) {
  .events__titles, .events__photo {
    grid-column: 1 / -1;
  }

  .events__photo {
    grid-area: 2 / 1 / 3 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto 0;
  }
}

#events_summary {
  padding-top: 0;
  padding-bottom: 0;
}

#events_summary .g {
  align-content: unset;
}

.events_summary__titles {
  grid-column: 1 / 7;
  align-self: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.events_summary__photo {
  grid-column: 8 / -2;
}

.events_summary__features {
  margin-top: 4rem;
}

@media screen and (width <= 768px) {
  #events_summary {
    background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2253%22%20height%3D%22610%22%20viewBox%3D%220%200%2053%20610%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cmask%20id%3D%22mask0%22%20mask-type%3D%22alpha%22%20maskUnits%3D%22userSpaceOnUse%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2253%22%20height%3D%22610%22%3E%0A%3Crect%20width%3D%2253%22%20height%3D%22610%22%20fill%3D%22%23C4C4C4%22%2F%3E%0A%3C%2Fmask%3E%0A%3Cg%20mask%3D%22url(%23mask0)%22%3E%0A%3Cpath%20d%3D%22M53%20218C-18.0495%20281.5%20-14.5956%20426%2053%20513%22%20stroke%3D%22%23404040%22%20stroke-dasharray%3D%2210%2010%22%2F%3E%0A%3C%2Fg%3E%0A%3Cg%20clip-path%3D%22url(%23clip0)%22%3E%0A%3Cpath%20d%3D%22M19.2511%20259.313L22.1809%20263.471L12.3631%20282.303L19.7862%20263.499L19.2511%20259.313Z%22%20fill%3D%22%23E22BDB%22%2F%3E%0A%3Cpath%20d%3D%22M11.2584%20264.133L19.7864%20263.5L12.3633%20282.303L11.2584%20264.133Z%22%20fill%3D%22%237F36F8%22%2F%3E%0A%3Cpath%20d%3D%22M22.1812%20263.471L27.5763%20262.407L12.3634%20282.303L22.1812%20263.471Z%22%20fill%3D%22%23F8B70C%22%2F%3E%0A%3Cpath%20d%3D%22M19.7863%20263.5L19.2511%20259.313L17.9537%20263.643L19.7863%20263.5Z%22%20fill%3D%22%23FE452B%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22clip0%22%3E%0A%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22white%22%20transform%3D%22translate(23.8584%20285.747)%20rotate(-163.321)%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A");
    background-position: 100% 0;
    background-repeat: no-repeat;
    padding-bottom: 0;
  }

  .events_summary__titles, .events_summary__features, .events_summary__photo {
    grid-column: 1 / -1;
  }

  .events_summary__photo {
    grid-column: 1 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto 0;
  }

  .events_summary__features {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}

#notes {
  display: flex;
}

.notes__titles {
  grid-column: 6 / -2;
  align-self: center;
}

.notes__photo {
  grid-column: 1 / 5;
  align-self: center;
}

@media screen and (width <= 768px) {
  #notes {
    background-image: url("data:image/svg+xml, %3Csvg%20width%3D%22112%22%20height%3D%22439%22%20viewBox%3D%220%200%20112%20439%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cmask%20id%3D%22mask0%22%20mask-type%3D%22alpha%22%20maskUnits%3D%22userSpaceOnUse%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22112%22%20height%3D%22439%22%3E%0A%3Crect%20width%3D%22112%22%20height%3D%22439%22%20fill%3D%22%23FCFDFF%22%2F%3E%0A%3C%2Fmask%3E%0A%3Cg%20mask%3D%22url(%23mask0)%22%3E%0A%3Cpath%20d%3D%22M112%20100C81.6059%20177.956%2067.9145%20221.674%2089.0928%20299.784C100.362%20334.33%20103.962%20363.088%20106.378%20410.025C107.218%20426.337%20103.129%20442.824%2092.8602%20455.526C68.3326%20485.868%2052.1823%20483.556%2016.8855%20477.092C1.57305%20474.287%20-3.80862%20466.196%20-4.8163%20457.602C-6.84198%20440.327%2011.0582%20427.86%2028.3267%20429.943C45.6017%20432.026%2067.3943%20441.047%2081.6231%20470.099C99.968%20502.737%20106.721%20522.82%20112%20562%22%20stroke%3D%22%23404040%22%20stroke-dasharray%3D%2210%2010%22%2F%3E%0A%3C%2Fg%3E%0A%3Cpath%20d%3D%22M90.4571%20313.852L95.3455%20315.259L99.4828%20336.09L93.4961%20316.781L90.4571%20313.852Z%22%20fill%3D%22%23E22BDB%22%2F%3E%0A%3Cpath%20d%3D%22M87.2437%20322.615L93.4961%20316.781L99.4828%20336.09L87.2437%20322.615Z%22%20fill%3D%22%237F36F8%22%2F%3E%0A%3Cpath%20d%3D%22M95.3457%20315.259L98.8855%20311.051L99.483%20336.09L95.3457%20315.259Z%22%20fill%3D%22%23F8B70C%22%2F%3E%0A%3C%2Fsvg%3E%0A");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 0;
  }

  .notes__titles, .notes__photo {
    grid-column: 1 / -1;
  }

  .notes__photo {
    grid-area: 2 / 1 / 3 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto 0;
  }
}

#notes_summary {
  padding-top: 0;
  padding-bottom: 0;
}

#notes_summary .g {
  align-content: unset;
}

.notes_summary__titles {
  grid-column: 1 / 7;
  align-self: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.notes_summary__photo {
  grid-column: 8 / -2;
}

.notes_summary__features {
  grid-template-columns: 1fr 1fr;
  margin-top: 4rem;
}

@media screen and (width <= 768px) {
  #notes_summary {
    background-image: url("data:image/svg+xml, %3Csvg%20width%3D%22118%22%20height%3D%22554%22%20viewBox%3D%220%200%20118%20554%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cmask%20id%3D%22mask0%22%20mask-type%3D%22alpha%22%20maskUnits%3D%22userSpaceOnUse%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22118%22%20height%3D%22554%22%3E%0A%3Crect%20width%3D%22118%22%20height%3D%22554%22%20fill%3D%22%23C4C4C4%22%2F%3E%0A%3C%2Fmask%3E%0A%3Cg%20mask%3D%22url(%23mask0)%22%3E%0A%3Cpath%20d%3D%22M118%20-339C87.6059%20-261.044%2073.9145%20-217.326%2095.0928%20-139.216C106.362%20-104.67%20109.962%20-75.9118%20112.378%20-28.9747C113.218%20-12.6625%20109.129%203.82388%2098.8602%2016.5263C74.3326%2046.8681%2058.1823%2044.5565%2022.8855%2038.0919C7.57305%2035.2874%202.19138%2027.1959%201.1837%2018.6023C-0.841979%201.32714%2017.0582%20-11.1398%2034.3267%20-9.05746C51.6017%20-6.97432%2073.3943%202.04738%2087.6231%2031.0995C105.968%2063.7369%20112.721%2083.8197%20118%20123%22%20stroke%3D%22%23404040%22%20stroke-dasharray%3D%2210%2010%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
    background-position: 100% 0;
    background-repeat: no-repeat;
    padding-bottom: 0;
  }

  .notes_summary__titles, .notes_summary__features, .notes_summary__photo {
    grid-column: 1 / -1;
  }

  .notes_summary__photo {
    grid-column: 1 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto 0;
  }

  .notes_summary__features {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}

#projects {
  display: flex;
}

.projects__titles {
  grid-column: 6 / -2;
  align-self: center;
}

.projects__photo {
  grid-column: 1 / 5;
  align-self: center;
}

@media screen and (width <= 768px) {
  #projects {
    padding-bottom: 0;
  }

  .projects__titles, .projects__photo {
    grid-column: 1 / -1;
  }

  .projects__photo {
    grid-area: 2 / 1 / 3 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto 0;
  }
}

#projects_summary {
  padding-top: 0;
  padding-bottom: 0;
}

#projects_summary .g {
  align-content: unset;
}

.projects_summary__titles {
  grid-column: 1 / 7;
  align-self: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.projects_summary__photo {
  grid-column: 8 / -2;
}

.projects_summary__features {
  grid-template-columns: 1fr 1fr;
  margin-top: 4rem;
}

@media screen and (width <= 768px) {
  #projects_summary {
    padding-bottom: 0;
  }

  .projects_summary__titles, .projects_summary__features, .projects_summary__photo {
    grid-column: 1 / -1;
  }

  .projects_summary__photo {
    grid-column: 1 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto 0;
  }

  .projects_summary__features {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}

#soon .g {
  grid-template-rows: repeat(5, auto);
  align-items: center;
}

.soon__title {
  grid-column: 1 / 8;
}

.soon__description {
  grid-column: 1 / 8;
  margin-bottom: 1.5rem;
}

.soon__photo {
  grid-area: 1 / 9 / -1 / -1;
  height: auto;
  margin: 1.5rem 0 2rem;
}

@media screen and (width <= 768px) {
  .soon__title, .soon__description {
    grid-column: 1 / -1;
  }

  .soon__photo {
    grid-area: 3 / 1 / 4 / -1;
    max-width: 260px;
    height: auto;
    margin: 1.5rem auto;
  }
}

.soon_summary__photo {
  grid-column: 2 / 5;
}

.soon_summary__photo img {
  object-position: center;
}

#soon_summary .feature__image {
  align-self: end;
  max-width: 260px;
  height: auto;
  margin: .5rem auto;
  display: none;
}

.soon_summary__slider {
  grid-column: 6 / -2;
  align-self: center;
  overflow: hidden;
}

#soon_summary .slider__navigation {
  display: none;
}

@media screen and (width <= 1024px) {
  .soon_summary__photo {
    grid-column: 1 / 5;
  }

  .soon_summary__slider {
    grid-column: 6 / -1;
  }
}

@media screen and (width <= 768px) {
  #soon_summary .feature__title, #soon_summary .feature__description, #soon_summary .feature__image {
    grid-column: 1 / -1;
  }

  #soon_summary .feature__title {
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }

  #soon_summary .feature__checkbox {
    display: none;
  }

  #soon_summary .feature__image {
    display: flex;
  }

  #soon_summary .feature {
    scroll-snap-align: center;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 1fr;
    height: 100%;
  }

  #soon_summary .slider__navigation {
    display: flex;
  }

  .soon_summary__slider {
    grid-column: 1 / -1;
  }

  .soon_summary__features {
    scroll-snap-type: x mandatory;
    grid-template-columns: repeat(5, 100%);
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .soon_summary__photo {
    display: none;
  }
}

#download {
  align-content: center;
  height: 43rem;
}

.download__title, .download__description, .download__store {
  grid-column: 1 / -1;
}

@media screen and (width <= 1440px) {
  #download {
    height: auto;
  }
}

#plane {
  opacity: 0;
  transform-origin: center;
  position: absolute;
}

#path_with_plane {
  z-index: -1;
  width: 100%;
  position: absolute;
}

@media screen and (width <= 90rem) {
  #path_with_plane, #plane {
    display: none;
  }
}

.slider {
  position: relative;
}

.slider::-webkit-scrollbar {
  display: none;
}

.slider__navigation {
  justify-content: center;
  gap: .25rem;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 2rem;
  left: 0;
}

.slider__control {
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  background: none;
  border-radius: 50%;
  padding: .25rem;
  display: inline-block;
}

.slider__control--active {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

.slider__control:focus {
  box-shadow: inset 0 0 0 1px var(--secondary-color);
  border-color: var(--secondary-color);
  outline: none;
}

.slider__control:active.slider__control--active, .slider__control:focus.slider__control--active {
  box-shadow: none;
  outline: none;
}

.store {
  grid-template: 1fr / 216px 1fr 216px 1fr;
  gap: 1rem;
  margin-top: 1rem;
  display: grid;
}

.store--bottom {
  grid-template: 1fr / 216px 0 216px;
  gap: 2rem;
}

.store__column--second {
  grid-area: 1 / 3 / 1 / span 1;
}

.store__column_title {
  color: #303030;
  font-size: 18px;
  font-weight: 900;
  line-height: 2.7rem;
}

.store__title_container {
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 1rem;
  display: flex;
}

.store__title_icon {
  height: 2rem;
  padding-right: .8rem;
}

@media screen and (width <= 1024px) {
  .store {
    grid-template: 1fr / 1fr 1fr;
  }

  .store__column--second {
    grid-area: 1 / 2 / 1 / span 1;
  }

  .store__column_title {
    font-size: .875rem;
    line-height: 1.05rem;
  }

  .store__title_container {
    height: 2rem;
  }

  .store__title_icon {
    height: 1.6rem;
    padding-right: .6875rem;
  }
}

@media screen and (width <= 768px) {
  .store__title_container {
    flex-direction: column;
    justify-content: space-around;
    height: 3.5rem;
  }

  .store__title_icon {
    height: 1.6rem;
    padding-right: 0;
  }
}

.store_button {
  color: #fff;
  background-color: #000;
  border-radius: .5rem;
  align-items: center;
  height: 4.5rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  display: flex;
}

.store_button__text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.store_button__image {
  justify-content: center;
  align-items: center;
  width: 2rem;
  max-height: 2.5rem;
  padding-right: 1rem;
  display: flex;
}

.store_button__title {
  font-size: .875rem;
  line-height: 1.05rem;
}

.store_button__subtitle {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.35rem;
}

@media screen and (width <= 1024px) {
  .store_button {
    height: 4rem;
    margin-bottom: .5rem;
  }

  .store_button__title {
    font-size: .75rem;
  }

  .store_button__subtitle {
    font-size: 1rem;
  }

  .store_button__image {
    width: 1.5rem;
  }
}

@media screen and (width <= 768px) {
  .store_button__image {
    padding-right: .5rem;
  }
}

.soon_button {
  color: #000;
  border: 2px solid #a9a9a9;
  border-radius: .5rem;
  align-items: center;
  height: 4.25rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  display: flex;
}

.soon_button__image {
  justify-content: center;
  align-items: center;
  width: 2rem;
  max-height: 2.5rem;
  padding-right: 1rem;
  display: flex;
}

.soon_button__text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.soon_button__title {
  font-size: .875rem;
}

.soon_button__subtitle {
  font-size: 1.125rem;
  font-weight: 700;
}

.soon_button__link_text {
  color: #007aff;
  text-decoration: underline;
}

.soon_button__title, .soon_button__link_text {
  line-height: 1.05rem;
}

.soon_button__subtitle {
  line-height: 1.35rem;
}

@media screen and (width <= 1024px) {
  .soon_button {
    height: 3.75rem;
    margin-bottom: .5rem;
  }

  .soon_button__title {
    font-size: .75rem;
  }

  .soon_button__subtitle {
    font-size: 1rem;
  }

  .soon_button__link_text {
    font-size: .75rem;
  }

  .soon_button__title, .soon_button__link_text {
    line-height: .9rem;
  }

  .soon_button__subtitle {
    line-height: 1.2rem;
  }

  .soon_button__image {
    width: 1.5rem;
  }
}

@media screen and (width <= 768px) {
  .soon_button__image {
    padding-right: .5rem;
  }
}



a:focus, input:focus, button:focus {
  outline-color: var(--accent-medium-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--accent-color);
}

.anchor {
  border-top: 2rem solid #0000;
  margin-top: -2rem;
}

#welcome:after, #tasks:after, #notes:after, #events:after, #soon:after {
  background-color: var(--preview-color);
  content: "";
  z-index: -2;
  position: absolute;
  inset: 0;
}

/*# sourceMappingURL=index.ba256eab.css.map */
