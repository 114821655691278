@import "./base.css";
@import "./keyframes.css";
@import "./typo.css";
@import "./layout.css";
@import "./header.css";
@import "./footer.css";
@import "./form.css";
@import "./modal.css";
@import "./features.css";
@import "./section_welcome.css";
@import "./section_tasks.css";
@import "./section_tasks_summary.css";
@import "./section_events.css";
@import "./section_events_summary.css";
@import "./section_notes.css";
@import "./section_notes_summary.css";
@import "./section_projects.css";
@import "./section_projects_summary.css";
@import "./section_soon.css";
@import "./section_soon_summary.css";
@import "./section_download.css";
@import "./path_with_plane.css";
@import "./slider.css";
@import "./store__badges.css";
@import "./mixins/mixins.css";

a:focus, input:focus, button:focus {
  outline-color: var(--accent-medium-color);
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  color: var(--accent-color);
}

.anchor {
  border-top: 2rem solid transparent;
  margin-top: -2rem;
}

#welcome:after, #tasks:after, #notes:after, #events:after, #soon:after {
  background-color: var(--preview-color);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -2;
}
