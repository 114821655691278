body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    font-family: 'lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-size: 4.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
    margin-top: 0;
}

h2 {
    font-size: 1.875rem;
    font-weight: 600;
    margin: 1rem 0;
}

h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

p {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 0.5rem;
}

button, input {
    font-size: 1.125rem;
}

@media screen and (max-width: 1440px) {
    h1 {
        font-size: 3.25rem;
        margin-bottom: 1.5rem;
    }

    h2 {
        font-size: 1.625rem;
    }

    p {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 1024px) {
    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 768px){
	h1 {
		font-size: 1.75rem;
        margin-bottom: 1rem;
	}

    h2 {
        font-size: 1.375rem;
    }

    p, button, input {
        font-size: 1rem;
    }
}
