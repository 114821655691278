.soon_summary__photo {
    grid-column: 2 / 5;
}

.soon_summary__photo img {
    object-position: center;
}

#soon_summary .feature__image {
    display: none;
    align-self: end;
    height: auto;
    max-width: 260px;
    margin: .5rem auto;
}

.soon_summary__slider {
    grid-column: 6 / -2;
    align-self: center;
    overflow: hidden;
}

#soon_summary .slider__navigation {
    display: none;
}

@media screen and (max-width: 1024px) {
    .soon_summary__photo {
        grid-column: 1 / 5;
    }

    .soon_summary__slider {
        grid-column: 6 / -1;
    }
}

@media screen and (max-width: 768px) {
    #soon_summary .feature__title, #soon_summary .feature__description, #soon_summary .feature__image {
        grid-column: 1 / -1;
    }

    #soon_summary .feature__title {
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }

    #soon_summary .feature__checkbox {
        display: none;
    }

    #soon_summary .feature__image {
        display: flex;
    }

    #soon_summary .feature {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        height: 100%;
        scroll-snap-align: center;
    }

    #soon_summary .slider__navigation {
        display: flex;
    }

    .soon_summary__slider {
        grid-column: 1 / -1;
    }

    .soon_summary__features {
        grid-template-columns: repeat(5, 100%);
        justify-content: space-between;
        gap: 1rem;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 2rem;
        scroll-snap-type: x mandatory;
    }

    .soon_summary__photo {
        display: none;
    }
}
