#tasks {
    padding-bottom: 0;
}

.tasks__titles {
    align-self: center;
    grid-column: 6 / -2;
}

.tasks__photo {
    grid-column: 1 / 5;
}

@media screen and (max-width: 768px) {
    #tasks {
        background-image: url("data:image/svg+xml, %3Csvg width='360' height='469' viewBox='0 0 360 469' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='360' height='469'%3E%3Crect width='360' height='469' fill='%23FCFDFF'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath d='M357.403 330C281.542 391.441 288.267 476.552 357.403 539' stroke='%23404040' stroke-dasharray='10 10'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
    }

    .tasks__titles, .tasks__photo {
        grid-column: 1 / -1;
    }

    .tasks__photo {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        height: auto;
        max-width: 260px;
        margin: 1.5rem auto 0;
    }
}
