#notes_summary {
    padding-top: 0;
    padding-bottom: 0;
}

#notes_summary .g {
    align-content: unset;
}

.notes_summary__titles {
    align-self: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-column: 1 / 7;
}

.notes_summary__photo {
    grid-column: 8 / -2;
}

.notes_summary__features {
    grid-template-columns: 1fr 1fr;
    margin-top: 4rem;
}

@media screen and (max-width: 768px) {
    #notes_summary {
        padding-bottom: 0;
        background-image: url("data:image/svg+xml, %3Csvg%20width%3D%22118%22%20height%3D%22554%22%20viewBox%3D%220%200%20118%20554%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cmask%20id%3D%22mask0%22%20mask-type%3D%22alpha%22%20maskUnits%3D%22userSpaceOnUse%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22118%22%20height%3D%22554%22%3E%0A%3Crect%20width%3D%22118%22%20height%3D%22554%22%20fill%3D%22%23C4C4C4%22%2F%3E%0A%3C%2Fmask%3E%0A%3Cg%20mask%3D%22url(%23mask0)%22%3E%0A%3Cpath%20d%3D%22M118%20-339C87.6059%20-261.044%2073.9145%20-217.326%2095.0928%20-139.216C106.362%20-104.67%20109.962%20-75.9118%20112.378%20-28.9747C113.218%20-12.6625%20109.129%203.82388%2098.8602%2016.5263C74.3326%2046.8681%2058.1823%2044.5565%2022.8855%2038.0919C7.57305%2035.2874%202.19138%2027.1959%201.1837%2018.6023C-0.841979%201.32714%2017.0582%20-11.1398%2034.3267%20-9.05746C51.6017%20-6.97432%2073.3943%202.04738%2087.6231%2031.0995C105.968%2063.7369%20112.721%2083.8197%20118%20123%22%20stroke%3D%22%23404040%22%20stroke-dasharray%3D%2210%2010%22%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: top;
    }

    .notes_summary__titles, .notes_summary__features, .notes_summary__photo {
        grid-column: 1 / -1;
    }

    .notes_summary__photo {
        grid-column: 1 / -1;
        height: auto;
        max-width: 260px;
        margin: 1.5rem auto 0;
    }

    .notes_summary__features {
        grid-template-columns: 1fr;
        margin-top: 1rem;
    }
}
