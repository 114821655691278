#soon .g {
    grid-template-rows: repeat(5, auto);
    align-items: center;
}

.soon__title {
    grid-column: 1 / 8;
}

.soon__description {
    grid-column: 1 / 8;
    margin-bottom: 1.5rem;
}

.soon__photo {
    grid-column: 9 / -1;
    grid-row: 1 / -1;
    height: auto;
    margin: 1.5rem 0 2rem;
}

@media screen and (max-width: 768px) {
    .soon__title, .soon__description {
        grid-column: 1 / -1;
    }

    .soon__photo {
        grid-column: 1 / -1;
        grid-row: 3 / 4;
        height: auto;
        max-width: 260px;
        margin: 1.5rem auto;
    }
}
