#projects {
    display: flex;
}

.projects__titles {
    align-self: center;
    grid-column: 6 / -2;
}

.projects__photo {
    align-self: center;
    grid-column: 1 / 5;
}

@media screen and (max-width: 768px) {
    #projects {
        padding-bottom: 0;
    }

    .projects__titles, .projects__photo {
        grid-column: 1 / -1;
    }

    .projects__photo {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        height: auto;
        max-width: 260px;
        margin: 1.5rem auto 0;
    }
}
