.features {
    display: grid;
    gap: 1.5rem;
    align-items: baseline;
}

.feature {
    align-items: center;
    display: grid;
    column-gap: 1.5rem;
    row-gap: .5rem;
    grid-template-columns: auto 1fr;
}

.feature__checkbox {
    grid-column: 1 / 2;
    height: 1.5rem;
    width: 1.5rem;
}

.feature__title {
    grid-column: 2 / 3;
    margin: 0;
    font-size: 1.5rem;
}

.feature__description {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
}

.features p{
	font-size: 1.2rem;
}

@media screen and (max-width: 1440px) {
    .features {
        gap: 2rem;
    }
}

@media screen and (max-width: 1024px) {
    .features {
       gap: 1.5rem;
    }

    .feature {
        column-gap: 1rem;
    }

    .feature__title {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 768px) {
    .features {
        gap: 1rem;
    }

    .feature__title {
        font-size: 1rem;
    }
	
	.features p{
		font-size: 1rem;
	}
}
