nav {
    background-color: #FFFFFF;
    box-shadow: 0 1px .5rem 1px #EEEEEE;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}

nav > .g {
    align-items: center;
    min-height: 4rem;
}

.nav__link {
    padding: 0.5rem;
    text-decoration: none;
    line-height: 1.25rem;
    font-size: 1rem;
    font-weight: 500;
}

.burger {
    display: none;
    width: min-content;
    padding: 0;
    background: transparent;
    color: var(--primary-color);
}

.logo {
    padding: .75rem 0;
}

.lang {
    grid-column: -2 / -1;
    grid-row: 1 / 2;
}

.nav {
    grid-column: 2 / -2;
    display: flex;
    align-items: center;
    gap: 4rem;
}

.nav--visible {
    display: flex !important;
}

.nav__overlay--visible {
    display: block !important;
}

#start {
    margin: 6rem auto 0;
    grid-template-rows: repeat(6, auto);
}

.start__title {
    grid-column: 1 / 8;
    margin-top: 6rem;
}

.start__description, .start__download {
    grid-column: 1 / 8;
}

.start__photo {
    grid-column: 8 / -2;
    grid-row: 1 / -1;
}

@media screen and (max-width: 1440px) {
    .start__title {
        grid-column: 1 / 7;
        margin-top: 10%;
    }

    .start__description, .start__download {
        grid-column: 1 / 7;
    }

}

@media screen and (max-width: 1240px) {
    .start__title {
        grid-column: 1 / 8;
    }
}

@media screen and (max-width: 1024px) {
    .start__title {
        margin-bottom: 1rem;
    }

    .start__photo {
        grid-column: 8 / -1;
        grid-row: 1 / -1;
    }

    .nav {
        gap: 2rem;
    }
}

@media screen and (max-width: 768px) {
    #start {
        margin-top: 4rem;
    }

    .start__title {
        grid-column: 1 / -1;
        margin-top: 0;
    }

    nav > .g {
        min-height: auto;
    }

    .start__description, .start__download {
        grid-column: 1 / -1;
    }

    .start__photo {
        grid-column: 1 / -1;
        grid-row: 3 / 4;
        height: auto;
        max-width: 260px;
        margin: 1.5rem auto;
    }

    .burger {
        display: block;
        grid-column: 1 / 2;
        grid-row: 1 / 1;
    }

    .logo {
        grid-column: 1 / -1;
        grid-row: 1 / 1;
        justify-self: center;
    }

    .nav {
        display: none;
        flex-direction: column;
        grid-column: 1 / -1;
        gap: 0;
        padding: 1rem;
    }

    .nav__link {
        width: 100%;
        text-align: center;
        padding: 1rem;
    }
}
