#notes {
    display: flex;
}

.notes__titles {
    align-self: center;
    grid-column: 6 / -2;
}

.notes__photo {
    align-self: center;
    grid-column: 1 / 5;
}

@media screen and (max-width: 768px) {
    #notes {
        padding-bottom: 0;
        background-image: url("data:image/svg+xml, %3Csvg%20width%3D%22112%22%20height%3D%22439%22%20viewBox%3D%220%200%20112%20439%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cmask%20id%3D%22mask0%22%20mask-type%3D%22alpha%22%20maskUnits%3D%22userSpaceOnUse%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22112%22%20height%3D%22439%22%3E%0A%3Crect%20width%3D%22112%22%20height%3D%22439%22%20fill%3D%22%23FCFDFF%22%2F%3E%0A%3C%2Fmask%3E%0A%3Cg%20mask%3D%22url(%23mask0)%22%3E%0A%3Cpath%20d%3D%22M112%20100C81.6059%20177.956%2067.9145%20221.674%2089.0928%20299.784C100.362%20334.33%20103.962%20363.088%20106.378%20410.025C107.218%20426.337%20103.129%20442.824%2092.8602%20455.526C68.3326%20485.868%2052.1823%20483.556%2016.8855%20477.092C1.57305%20474.287%20-3.80862%20466.196%20-4.8163%20457.602C-6.84198%20440.327%2011.0582%20427.86%2028.3267%20429.943C45.6017%20432.026%2067.3943%20441.047%2081.6231%20470.099C99.968%20502.737%20106.721%20522.82%20112%20562%22%20stroke%3D%22%23404040%22%20stroke-dasharray%3D%2210%2010%22%2F%3E%0A%3C%2Fg%3E%0A%3Cpath%20d%3D%22M90.4571%20313.852L95.3455%20315.259L99.4828%20336.09L93.4961%20316.781L90.4571%20313.852Z%22%20fill%3D%22%23E22BDB%22%2F%3E%0A%3Cpath%20d%3D%22M87.2437%20322.615L93.4961%20316.781L99.4828%20336.09L87.2437%20322.615Z%22%20fill%3D%22%237F36F8%22%2F%3E%0A%3Cpath%20d%3D%22M95.3457%20315.259L98.8855%20311.051L99.483%20336.09L95.3457%20315.259Z%22%20fill%3D%22%23F8B70C%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: bottom;
    }

    .notes__titles, .notes__photo {
        grid-column: 1 / -1;
    }

    .notes__photo {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        height: auto;
        max-width: 260px;
        margin: 1.5rem auto 0;
    }
}
