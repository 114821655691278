.tasks_summary__titles {
    align-self: center;
    grid-column: 1 / 5;
}

.tasks_summary__photo {
    grid-column: 6 / 9;
}

.tasks_summary__photo img {
    object-position: center;
}

.tasks_summary__features {
    margin-top: 4rem;
}

@media screen and (max-width: 768px) {
    #tasks_summary {
        padding-bottom: 0;
        background-image: url("data:image/svg+xml, %3Csvg width='360' height='572' viewBox='0 0 360 572' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='360' height='572'%3E%3Crect width='360' height='572' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3Cpath d='M360 503C261.638 462.335 176.523 403.5 359.999 203.5' stroke='%23404040' stroke-dasharray='10 10'/%3E%3Cpath d='M357.403 -139C281.542 -77.559 288.268 7.55182 357.403 70' stroke='%23404040' stroke-dasharray='10 10'/%3E%3C/g%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M315.78 255.853L316.747 260.847L300.072 273.999L314.551 259.891L315.78 255.853Z' fill='%23E22BDB'/%3E%3Cpath d='M306.513 256.973L314.552 259.892L300.072 273.999L306.513 256.973Z' fill='%237F36F8'/%3E%3Cpath d='M316.747 260.848L322.105 262.088L300.073 273.999L316.747 260.848Z' fill='%23F8B70C'/%3E%3Cpath d='M314.54 259.928L315.768 255.89L312.809 259.308L314.54 259.928Z' fill='%23FE452B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='24' height='24' fill='white' transform='translate(309.146 281.853) rotate(-139.12)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position-x: right;
        background-position-y: top;
    }

    .tasks_summary__titles, .tasks_summary__photo {
        grid-column: 1 / -1;
    }

    .tasks_summary__photo {
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        height: auto;
        max-width: 260px;
        margin: 1.5rem auto 0;
    }

    .tasks_summary__features {
        margin-top: 1rem;
    }
}
