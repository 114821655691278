.footer__social {
    display: grid;
    grid-auto-flow: column;
}

.footer__social a {
    padding: .5rem;
}

.footer__social, .footer__agreement, .footer__copyright {
    grid-column: 1 / -1;
    justify-content: center;
    text-align: center;
}
.footer__copyright {
    padding: .5rem 0 2rem;
    color: gray;
    font-size: .8rem;
}
