.store_button {
    background-color: black;
    display: flex;
    color: white;
    padding-left: 1rem;
    border-radius: 0.5rem;
    height: 4.5rem;
    align-items: center;
    margin-bottom: 1rem;
}

.store_button__text {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.store_button__image  {
    width: 2rem;
    max-height: 2.5rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.store_button__title {
    font-size: 0.875rem;
    line-height: 1.05rem;
}

.store_button__subtitle {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.35rem;
}

@media screen and (max-width: 1024px) {
    .store_button {
        height: 4rem;
        margin-bottom: 0.5rem;
    }

    .store_button__title {
        font-size: 0.75rem;
    }

    .store_button__subtitle {
        font-size: 1rem;
    }

    .store_button__image  {
        width: 1.5rem;
    }
}

@media screen and (width <= 768px) {
    .store_button__image  {
        padding-right: 0.5rem;
    }
}