#events_summary {
	padding-top: 0;
	padding-bottom: 0;
}

#events_summary .g {
	align-content: unset;
}

.events_summary__titles {
    align-self: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
    grid-column: 1 / 7;
}

.events_summary__photo {
    grid-column: 8 / -2;
}

.events_summary__features {
    margin-top: 4rem;
}


@media screen and (max-width: 768px) {
	#events_summary {
		padding-bottom: 0;
		background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2253%22%20height%3D%22610%22%20viewBox%3D%220%200%2053%20610%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cmask%20id%3D%22mask0%22%20mask-type%3D%22alpha%22%20maskUnits%3D%22userSpaceOnUse%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2253%22%20height%3D%22610%22%3E%0A%3Crect%20width%3D%2253%22%20height%3D%22610%22%20fill%3D%22%23C4C4C4%22%2F%3E%0A%3C%2Fmask%3E%0A%3Cg%20mask%3D%22url(%23mask0)%22%3E%0A%3Cpath%20d%3D%22M53%20218C-18.0495%20281.5%20-14.5956%20426%2053%20513%22%20stroke%3D%22%23404040%22%20stroke-dasharray%3D%2210%2010%22%2F%3E%0A%3C%2Fg%3E%0A%3Cg%20clip-path%3D%22url(%23clip0)%22%3E%0A%3Cpath%20d%3D%22M19.2511%20259.313L22.1809%20263.471L12.3631%20282.303L19.7862%20263.499L19.2511%20259.313Z%22%20fill%3D%22%23E22BDB%22%2F%3E%0A%3Cpath%20d%3D%22M11.2584%20264.133L19.7864%20263.5L12.3633%20282.303L11.2584%20264.133Z%22%20fill%3D%22%237F36F8%22%2F%3E%0A%3Cpath%20d%3D%22M22.1812%20263.471L27.5763%20262.407L12.3634%20282.303L22.1812%20263.471Z%22%20fill%3D%22%23F8B70C%22%2F%3E%0A%3Cpath%20d%3D%22M19.7863%20263.5L19.2511%20259.313L17.9537%20263.643L19.7863%20263.5Z%22%20fill%3D%22%23FE452B%22%2F%3E%0A%3C%2Fg%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22clip0%22%3E%0A%3Crect%20width%3D%2224%22%20height%3D%2224%22%20fill%3D%22white%22%20transform%3D%22translate(23.8584%20285.747)%20rotate(-163.321)%22%2F%3E%0A%3C%2FclipPath%3E%0A%3C%2Fdefs%3E%0A%3C%2Fsvg%3E%0A");
		background-repeat: no-repeat;
		background-position-x: right;
		background-position-y: top;
	}

	.events_summary__titles, .events_summary__features, .events_summary__photo {
		grid-column: 1 / -1;
	}

	.events_summary__photo {
		grid-column: 1 / -1;
		height: auto;
		max-width: 260px;
		margin: 1.5rem auto 0;
	}

	.events_summary__features {
		grid-template-columns: 1fr;
		margin-top: 1rem;
	}
}
