#download {
    align-content: center;
    height: 43rem;
}

.download__title, .download__description, .download__store {
    grid-column: 1 / -1;
}

@media screen and (max-width: 1440px) {
    #download {
        height: auto;
    }
}
