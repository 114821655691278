.slider {
    position: relative;
}

.slider::-webkit-scrollbar {
    display: none;
}

.slider__navigation {
    bottom: 2rem;
    display: flex;
    justify-content: center;
    gap: .25rem;
    left: 0;
    position: absolute;
    width: 100%;
}

.slider__control {
    background: transparent;
    border: 1px solid var(--secondary-color);
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    padding: .25rem;
}

.slider__control--active {
    background: var(--secondary-color);
    border-color: var(--secondary-color);
}

.slider__control:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px var(--secondary-color);
    border-color: var(--secondary-color);
}

.slider__control:active.slider__control--active, .slider__control:focus.slider__control--active {
    outline: none;
    box-shadow: none;
}
