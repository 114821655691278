.g {
    align-content: center;
    box-sizing: border-box;
    column-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: 90rem;
    margin: 0 auto;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 100%;
}

main {
    position: relative;
}

section {
    height: 780px;
    position: relative;
}

picture, img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: bottom;
    z-index: 1;
}

@media screen and (max-width: 1440px) {
    .g {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    section {
        height: auto;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media screen and (max-width: 768px){
	section {
        height: auto;
	}

    .g {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
