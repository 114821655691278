#welcome {
    display: flex;
}

.welcome__titles {
    grid-column: 1 / 9;
}

.welcome__benefits {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, minmax(18rem, 20rem));
    justify-content: space-between;
    overflow-y: hidden;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

.welcome__slider {
    grid-column: 1 / -1;
    margin-top: 4rem;
    overflow: hidden;
}

.benefit {
    scroll-snap-align: center;
    width: 100%;
}

.benefit p {
    font-size: 1.25rem;
}

.benefit__image {
    max-height: 10rem;
}

#welcome .slider__navigation {
    display: none;
}

@media screen and (max-width: 1024px) {
    .welcome__titles {
        grid-column: 1 / -1;
    }

    #welcome .slider__navigation {
        display: flex;
    }

    .welcome__benefits {
        grid-template-columns: repeat(3, calc(100%));
        padding-bottom: 2rem;
    }

    .benefit {
        width: 100%;
        display: grid;
        gap: 1rem;
        grid-template-columns: auto auto;
        justify-content: center;
        align-items: center;
    }

    .benefit__image {
        justify-self: center;
        width: auto;
    }

    .benefit__titles {
        max-width: 30rem;
    }
}

@media screen and (max-width: 768px) {
    .welcome__titles {
        grid-column: 1 / -1;
    }

    .benefit p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 540px) {
    .benefit {
        grid-template-columns: auto;
    }

    .benefit__titles {
        max-width: 20rem;
    }
}
