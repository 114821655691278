:root {
    --primary-color: #232326;
    --secondary-color: #616161;
    --accent-color: #1C59F6;
    --accent-medium-color: #007AFF;
    --preview-color: #FCFDFF;
}

html,
body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: Lato, Avenir, "Adobe Heiti Std", "Segoe UI", "Trebuchet MS", sans-serif;
    color: var(--primary-color);
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

img[loading="lazy"] {
    opacity: 0;
}

img.loaded {
    opacity: 1;
}
