.form {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 1rem;
    margin-top: 2rem;
}

.form__email {
    grid-column: 1 / 4;
}

.form__submit {
    grid-column: 4 / -1;
}

.form__footer {
    grid-column: 1 / -1;
}

.form__error {
    color: red;
    font-size: 1rem;
    margin: 0;
    padding-top: .5rem;
}

.form__error--hidden {
    display: none;
}

input {
    appearance: none;
    line-height: 1.375rem;
    padding: 1rem;
    border: 2px solid var(--secondary-color);
    border-radius: 4px;
}

button {
    line-height: 1.375rem;
    padding: 1rem;
    color: white;
    border: none;
    background: var(--accent-medium-color);
    border-radius: 4px;
}

@media screen and (max-width: 1024px) {
    .form {
        row-gap: 1rem;
        margin-top: 1.5rem;
    }

    .form__email, .form__submit {
        grid-column: 1 / -1;
    }

    input, button {
        padding: .75rem;
    }
}
